<template>
  <div>
    <v-card flat>
      <v-toolbar flat>
        <v-select
          label="Filter by Year"
          v-model="selectedYear"
          @change="month_get()"
          :items="filteredYears"
          class="ml-2 mt-6"
          dense
          outlined
          style="max-width: 150px"
        >
        </v-select>
        <v-select
          label="Filter by Month"
          item-text="text"
          item-value="value"
          v-model="selectedMonth"
          :items="formattedMonthItems"
          dense
          outlined
          class="ml-2 mt-6"
          style="max-width: 150px"
        >
        </v-select>
        <v-btn
          @click="getlivereport()"
          :loading="isLoading"
          small
          class="mr-2 ml-2 mt-1"
          color="primary white--text"
          >go</v-btn
        >
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :height="height"
          class="overflow-y-auto overflow"
          dense
          :headers="MembersDataActive"
          :items="MembersItems"
          :items-per-page="15"
          :fixed-header="fixed"
          :options.sync="pagination"
          :loading="isLoadingMember"
          loading-text="Loading... Please wait"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 25],
          }"
        >
          <template v-slot:[`no-data`]>
            <v-alert class="gradient-bg2 white--text">{{
              "Select a month and year, then click  Go to obtain the report"
            }}</v-alert>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-icon
              @click.stop="view_track_swipes(item)"
              small
              class="primary--text"
              >mdi-map-marker-account
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div v-if="componentCheck == 1">
      <ReportLivetracking
        @close_view_member_details="viewMembertrackDialog = false"
        :viewMembertrackDialog="viewMembertrackDialog"
        :userDetailsObj="userDetailsObj"
        :monthdate="monthdate"
      />
    </div>
    <SnackBar :SnackBarComponent="SnackBarComponent" />
  </div>
</template>

<script>
import ReportLivetracking from "@/components/Dialogs/ReportLivetracking.vue";
import SnackBar from "@/components/SnackBar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { getLivetrackingReport } from "@/graphql/queries.js";
export default {
  components: {
    SnackBar,
    ReportLivetracking,
  },
  data() {
    return {
      height: 0,
      SnackBarComponent: {},
      userDetailsObj: {},
      componentCheck: 0,
      isLoadingMember: false,
      viewMembertrackDialog: false,
      isLoading: false,
      selectedYear: new Date().getFullYear(),
      MembersDataActive: [
        {
          text: "Member Id",
          value: "employee_id",
          fixed: true,
          sortable: false,
        },
        {
          text: "Member Name",
          value: "user_name",
          fixed: true,
          sortable: false,
        },

        { text: "Actions", value: "Actions", sortable: false },
      ],
      MembersItems: [],
      fixed: true,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      selectedMonth: "",
      search: "",
      selectedDate: "",
      formattedMonthItems: [],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    filteredYears() {
      const currentYear = new Date().getFullYear();
      var dateComponents = this.selectedDate.split(/[\/,\s:]+/);
      // Extracting components
      var month = parseInt(dateComponents[0], 10) - 1; // Month is zero-indexed in JavaScript Date
      var day = parseInt(dateComponents[1], 10);
      var year = parseInt(dateComponents[2], 10);
      var hour = parseInt(dateComponents[3], 10);
      var minute = parseInt(dateComponents[4], 10);
      var second = parseInt(dateComponents[5], 10);
      var period = dateComponents[6]; // "AM" or "PM"

      // Adjust hour based on AM/PM
      if (period === "PM" && hour !== 12) {
        hour += 12;
      } else if (period === "AM" && hour === 12) {
        hour = 0;
      }

      var date = new Date(year, month, day, hour, minute, second);

      const selectedDateYear = date.getFullYear();
      const years = [];

      for (let year = selectedDateYear; year <= currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },
  async mounted() {
    this.selectedDate = this.get_date(
      this.$store.getters.get_org_details[0].organisation_created_on
    );
    this.height = window.innerHeight - 195;
    await this.month_get();
    // await this.getlivereport();
  },
  methods: {
    view_track_swipes(item) {
      this.componentCheck = 1;
      this.viewMembertrackDialog = true;
      this.userDetailsObj = item;
      this.monthdate = this.selectedYear.toString() + "-" + this.selectedMonth;
    },
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-US", {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    async month_get() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonthIndex = currentDate.getMonth();

      let newMonthItems = [];

      const month = this.selectedDate.match(/^(\d{2})\//)[1];
      // if (this.selectedYear === new Date(this.selectedDate).getFullYear()) {

      //   newMonthItems = this.monthNames.slice();
      // }

      if (this.selectedYear < currentYear) {
        newMonthItems = this.monthNames.slice();
      } else if (this.selectedYear === currentYear) {
        newMonthItems = this.monthNames.slice(0, currentMonthIndex + 1);
      } else {
        newMonthItems = this.monthNames.slice();
      }

      this.monthItems = newMonthItems.slice();

      this.formattedMonthItems = this.monthItems.map((month, index) => ({
        text: month,
        value: (index + 1).toString().padStart(2, "0"),
      }));
      this.selectedMonth = (currentMonthIndex + 1).toString().padStart(2, "0");
      if (this.selectedYear == new Date(this.selectedDate).getFullYear()) {
        const startIndex = this.formattedMonthItems.findIndex(
          (item) => item.value === month
        );

        const filteredArray =
          startIndex !== -1 ? this.formattedMonthItems.slice(startIndex) : [];

        this.formattedMonthItems = [];
        this.formattedMonthItems = filteredArray;

        this.selectedMonth = "";
        this.selectedMonth = month;
      }

      this.$forceUpdate();
    },
    async getlivereport() {
      this.isLoadingMember = true;
      this.overlay = true;
      try {
        let result = await API.graphql(
          graphqlOperation(getLivetrackingReport, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              track_month:
                this.selectedYear.toString() + "-" + this.selectedMonth,
            },
          })
        );
        var response = JSON.parse(result.data.getLivetrackingReport);

        if (response.Status == "SUCCESS") {
          this.MembersItems = response.data;
        } else {
          this.MembersItems = [];
        }

        this.isLoadingMember = false;
        this.overlay = false;
      } catch (err) {
        console.log("error", err);
        this.MembersItems = [];
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: err.errors[0].message,
        };
        this.isLoadingMember = false;
        this.overlay = false;
      }
    },
  },
};
</script>

<style>
</style>